<template>
    <ion-page>
        <ion-content padding>
            <ion-card class="page404-card">
                <ion-card-content>
                    <h1 class="ion-text-center mb-5">
                        {{ $t("nothingHere") }}
                    </h1>
                    <ion-button router-link="/" expand="block"
                        >Go Home</ion-button
                    >
                </ion-card-content>
            </ion-card>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Page404.ts"></script>
<style lang="scss" src="./Page404.scss"></style>
